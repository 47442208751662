.marquee-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    /* Stack vertically */
  }
  
  .marquee-content,
  .marquee-content-2 {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
 
  }
  
  .marquee-content {
    animation: marquee-left 80s linear infinite;
  }
  
  .marquee-content-2 {
    margin-top: 20px;
    /* Adjust if needed */  animation: marquee-right 80s linear infinite;
  }
  
  /* Vineeth CSS */


  /* CSS of page 7 */
 
  /* CSS of page 7 */
  .carousel-item img{
    width: 100% !important;
  }
  .card-body{
    padding: 0;
  }

  .cards,
  .card2 {
    width: 450px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 3.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 13px 48px 0 rgba(215,216,222,.44);
  }
  /* .cards,
  .card2 {
    flex: auto;
    width: 450px;
    height: 250px;
    margin-right: 25px;
    border-radius: 7px;
    box-shadow: 0 13px 48px 0 rgba(215, 216, 222, .44); 
    font-size: 1.1em;
  } */
  
 
  
  .card2 {
    background: #fff;
  }
  
  /* Keyframes for scrolling left */
  @keyframes marquee-left {
  
    100% {
      transform: translateX(-100%);
    }
  
    /* Move to the left */
  }
  
  /* Keyframes for scrolling right */
  @keyframes marquee-right {
    0% {
      transform: translateX(-100%);
    }
  
  }



  /* Page6.css */
.card-color-1 {
  background-color: white; /* Light grey background */
  color: #6B7385;

}
.card-color-1 .rbt-author-info p {
  color: black; /* Sets the text color to black */
}

.card-color-2 {
  background: #2a619d;
  color: white !important;
}

/* Vineeth CSS */
.card-img-tops{
   width: 75px;
    display: block; 
    margin-top: 10px!important;
    
}
/* .card-img-tops{
   width: 100px;
    display: block; 
    margin: 30px auto;
} */
  .card-text {
    font-size: 1rem;
    line-height: 1.45;
    text-align: center;
    text-wrap: wrap;
    padding: 10px;
  }
  
  .card-title {
    /* Center content horizontally and vertically */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .thumb img {
    max-width: 100%;
    /* Ensure image scales responsively */
    height: auto;
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -26px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
  }
  .marquee-title{
    font-size: 35px;
  }
  .awards-title{
    font-size: 35px;
    color: white;
  }
  .img-border-bottom {
    border-bottom: 1px solid gray; /* Adjust color and width as needed */
    padding-bottom: 10px; /* Optional: Adds some space below the image */
}
.wid-75{
  height: 400px!important;
  padding: 10px;
}
.wid-85{
  height: 400px!important;
  padding: 10px;
}
.wid-95{
  height: 400px!important;
  padding: 10px;
}
.wid-100{
  height: 390px!important;
  padding: 10px;
}
 .card-bg .card{
  background: transparent !important;
  border: none !important;
  overflow: hidden !important;
}
.card-desc{
  /* background: #fff; */
  color: white !important;
}
/* .fitImgs{
  padding: 1rem !important;
  background: #fff !important;
  height: 70% !important;
  width: 100% !important;
  border: none !important;
}
.fitImgs img{
  object-fit: contain;
  height: 100% !important;
  width: auto !important;
} */
.certificate-btn{
  height: 130px !important;
  width: 100px !important;
  padding: 5px 8px !important;
  background: transparent !important;
  margin-right: 10px !important;
}
.certificate-btn img{
  width: 100% !important;
  /* height: 100% !important; */

}
.certificates-btn li .nav-link.active{
  background: transparent !important;
  border: 1px solid #ccc !important;
  background: #4b5bc06b !important;
}
.certificates-btn li .nav-link{
  background: transparent !important;
  border: 1px solid #ccc !important;
}
.award-img{
  width: 200px;
  height: 500px;
}
.costum-card{
  height: 500px !important;
}


@media(min-width:1451px) and (max-width:2560px){
  .costum-card{
    height: 600px !important;
  }  
}
@media(max-width:1450px){
  .costum-card{
    height: 600px !important;
  }  
}
@media(max-width:1399px){
  .costum-card{
    height: 530px !important;
  }  
}
@media(max-width:1199px){
  .costum-card{
    height: 430px !important;
  }  
  .card-text {
    font-size: 0.8rem;
    line-height: 1;
    text-align: center;
    text-wrap: wrap;
    padding: 1px;
  }
}
/* @media(max-width:767px){
  .costum-card{
    height: 500px !important;
  }   
}*/
@media (min-width: 768px) and (max-width: 991px) {
  .costum-card {
    height: 330px !important;
  }
  .card-text {
    font-size: 0.8rem;
    line-height: 1;
    text-align: center;
    text-wrap: wrap;
    padding: 1px;
  }
}
@media (max-width: 575px) and (min-width:401px)  {
  .costum-card {
    height: 850px !important;
  }
}
@media (max-width: 400px) {
  .costum-card {
    height: 760px !important;
  }
}
.navs-color{
  background-color: white!important; /* Set default background color to white */
  color: black!important; /* Set default text color */

}
.navs-color.active {
  background-color: #2a619d!important; /* Color for active tab background */
  color: white!important; /* Text color for active tab */
 
}

/* Optional: Hover state for better UX */
.navs-color:hover {
  background-color: #f8f9fa!important; /* Slightly different background on hover */
  color: black!important;
}
.tab-contents {
  height: auto; /* Ensure the container can adjust to the height of its content */
  display: flex;
  justify-content: center;
  align-items: center;
}



.tab-img {
  max-width: 100%;
  height: auto;
  max-height: 580px; /* Increase the height as needed */
  object-fit: cover; /* Ensures the image covers the dimensions without distortion */
}

