.error_page {
    background-color: #000;
}

.qrcode {
    width: 60% !important;
    height: 60% !important;
    margin-bottom: 10px;
}
.scanner_star{
   color: orange;
}
.review_star{
    background-color: #87e9e9;
    padding: 5px;
    border-radius: 22px;
}
.qr_Scannner_bg {
    background: linear-gradient(106.37deg,
            #2f59ff 29.63%,
            #c87ff5 51.55%,
            #fbb634 90.85%);
            /* background-image: url(../images/BG.jpg); */
    height: 100vh;
    width: auto;
}
.qr_img{
    border-style: dotted;
    border-color:orange;
}
.qr_code_glass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    /* padding: 20px; */
    border-radius: 22px;
    /* background-color: rgb(243, 243, 243); */
    background-color: #fff;
    overflow: hidden;
}
