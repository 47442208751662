.blog_post_container {
   
    background-color: #f9f9ff;
  }
 
  @media (min-width: 768px) {
    .blog_post_container {
      padding: 30px 30px;
    }
  }
 
  @media (min-width: 1200px) {
    .blog_post_container {
      padding: 30px 220px;
    }
  }
 
  /* Button animation styles */
  .blogpost-animated-button {
    position: relative;
    padding: 8px 20px;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    background-color: inherit;
    border: none;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.6s cubic-bezier(0.23, 1, 0.32, 1),
      box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1),
      transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), border-radius 0.6s linear;
  }
 
  .blogpost-animated-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding: 2px; /* Adjust the thickness of the border */
    background: linear-gradient(90deg, #2a619d, #eb6329);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 0; /* Make sure the pseudo-element is behind the button content */
  }
 
  .blogpost-animated-button .text,
  .blogpost-animated-button svg,
  .blogpost-animated-button .circle {
    position: relative;
    z-index: 1; /* Place these elements above the pseudo-element */
  }
 
  .blogpost-animated-button .text {
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
 
  .blogpost-animated-button svg {
    position: absolute;
    width: 22px;
    fill: black;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
 
  .blogpost-animated-button .arr-1 {
    right: 2px;
  }
 
  .blogpost-animated-button .arr-2 {
    left: -25%;
  }
 
  .blogpost-animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    color: black;
    border-radius: 100px;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
 
  .blogpost-animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
  }
 
  .blogpost-animated-button:hover .arr-1 {
    right: -25%;
  }
 
  .blogpost-animated-button:hover .arr-2 {
    left: 16px;
  }
 
  .blogpost-animated-button:hover .text {
    transform: translateX(12px);
  }
 
  .blogpost-animated-button:hover svg {
    fill: black;
  }
 
  .blogpost-animated-button:active {
    scale: 0.95;
  }
 
  .blogpost-animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  .blog_card_main{
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border: 1px solid #ddd; /* Set border thickness and color */
    border-radius: 5px; /* Optional: Rounds the corners */
    overflow: hidden; /* Ensures the content fits within the border */
  }
  .blog_card_main:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(0.9);
  }
  .blog_card{
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .blog_card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scaleY(1.1);
    /* padding: 10px 20px; */
}
.blog_card {
    border: 1px solid #ddd; /* Set border thickness and color */
    border-radius: 5px; /* Optional: Rounds the corners */
    overflow: hidden; /* Ensures the content fits within the border */
  }
 
  .blog_card-body {
    padding: 1rem; /* Optional: Adjust padding as needed */
  }
 
 
  .blogpost-card-text {
    margin: 0;
    padding:0px 7px;
    font-size: 14px;
  }
  .blogpost-card-title{
    font-size: 19px;
    font-weight: 600;
    padding:5px 7px
  }
  @media (max-width: 768px) {
    .blogpost-card-title {
        font-size: 12px;
        font-weight: 600;
    }
  }


  /**/
  .post-hover {
    transition: transform 0.3s ease; /* Adjust the duration and easing as needed */
  }
  
  .post-hover:hover {
    transform: scale(1.05); /* Adjust the scale value to control the zoom level */
  }