/* Navbar.css */
.tab-bar {
  margin: 0px;
  padding: 0px;
  display: flex;
  list-style-type: none;
}

.tab {
  width: 100%;
  padding: 20px 0;
  color: #000;
  overflow: hidden;
  text-align: center;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.tab.active {
  color: var(--erp-teks-orange) !important; /* Apply the desired color */
}

/* Optional: Add hover effect */
.tab:hover {
  color: var(--erp-teks-orange) !important;
}

.nav-imgs{
  width: 230px;
}
/* Adjust the dropdown menu position and visibility */
.dropdown-submenu {
  position: relative;
}
 
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
  display: none;
}
 
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
 
.dropdown-submenu:hover > .dropdown-item {
  background-color: #f8f9fa; /* optional: change background on hover */
}
 
/* Ensure dropdowns are aligned properly */
.dropdown-menu-hover {
  display: block;
}
.dropdown-hover > .dropdown-menu-hover{
  display: none;
}
 
.dropdown-hover:hover > .dropdown-menu-hover {
  display: block;
}
 
.dropdown-hover .dropdown-menu {
  margin-left: 0;
}
 
.dropdown-hover .dropdown-menu-hover {
  margin-left: 0;
}
 /* course btn style  */
.cat-des {
  border: none;
  background-color: transparent;
  color: black;
  font-weight: 500!important;
  font-size: 18px;
  transition: all 0.3s;
  transform: translateY(0);
}
 /* Optional: Adjust text color */
  /* Optional: Add a background color */
/* Remove border from the dropdown */


/* course btn default arrow */
.dropdown-toggle::after{
  display: none !important;
}

.dropdown-width{
  width: 230px!important;
}

.dropdown-menu {
  margin-left: -300px;
  padding: 20px 0px!important;
}


/* new section */
.all-course{
  background: rgba(62, 92, 175, 0.295);
  /* min-width: 310px; */
  width: 800px !important;
  top: 180%;
  left: -90px;
  z-index: 5;
  position: absolute !important;
  transition: all 0.3s;
}
.hide{
  display: none !important;
}

.costum-height{
  height: 50vh !important;
  overflow-y: scroll;
}
.course-side {
  width: 100px;
  max-height: 800px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
}


.course-side::-webkit-scrollbar {
  width: 6px; /* Set scrollbar width */
}

.course-side::-webkit-scrollbar-track {
  background: transparent; /* Hide the track */
}

.course-side::-webkit-scrollbar-thumb {
  background: #c0bfbf; /* Scrollbar thumb color */
  border-radius: 3px; /* Optional: for rounded corners */
}

.course-side::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Hide scroll arrows in WebKit browsers */
.course-side::-webkit-scrollbar-button {
  display: none; /* Hide arrows */
}
.costum-height::-webkit-scrollbar {
  width: 6px; /* Set scrollbar width */
}

.costum-height::-webkit-scrollbar-track {
  background: transparent; /* Hide the track */
}

.costum-height::-webkit-scrollbar-thumb {
  background: #c0bfbf; /* Scrollbar thumb color */
  border-radius: 3px; /* Optional: for rounded corners */
}

.costum-height::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Hide scroll arrows in WebKit browsers */
.costum-height::-webkit-scrollbar-button {
  display: none; /* Hide arrows */
}

