.newsletter_background_img {
    background-color: #2a619d;
    background-image: url(../../assets/images/dots.png);
    width: 100%;
    padding: 70px;
  }
  .button-bg-blue {
    background-color: #ffffff21;
  }
  /* .button-gradient {
    background: linear-gradient(90deg, #b966e7, #2f57ef);
    border: transparent;
    border-radius: 5px;
    color: white;
    padding: 5px 20px;
    font-size: small;
  } */
  .email_input::placeholder {
    font-size: 0.8em;
    color: #999;
  }
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
   
  .flex-item {
    padding: 0 20px;
  }
  .small-placeholder::placeholder {
    font-size: 14px;
    color: #888;
  }
   
  .newsletter-divider-line {
    width: 1px;
    background-color: rgba(210, 210, 210, 0.7);
    height: 100px;
  }
  .text-lightcolor {
    color: rgba(255, 255, 255, 0.7);
  }
  .animated-text {
    display: inline-block;
    position: relative;
  }
  .animated-text:hover {
    animation: upDown 1.5s ease-in-out infinite alternate;
  }
   
  @keyframes upDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-box {
    padding: 6px;
    border-radius: 5px;
    background: #fff;
  }
  .form-box input {
    font-size: 18px;
    color: #000;
    padding: 5px 10px;
    border: none;
    outline: none;
    background: #fff;
  }
  /* .form-box button {
    color: #fff;
    padding: 10px 29px;
    font-size: 14px;
    border: none;
    background: linear-gradient(90deg, #b966e7, #2f57ef);
    cursor: pointer;
    transition: 0.3s;
  } */
   
  .form-box button:hover {
    background: #fff;
    color: black;
  }
   
  /* Button animation */
  .newsletter-animated-button {
    position: relative;
    padding: 0px 28px;
    font-size: 14px;
    font-weight: 600;
   
    background-color: inherit;
  outline: none;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.6s cubic-bezier(0.23, 1, 0.32, 1),
      box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1),
      transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), border-radius 0.6s linear;
  }
   
  .newsletter-animated-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    padding: 2px;
    background: #fff;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 0;
  }
   
  .newsletter-animated-button .text,
  .newsletter-animated-button svg,
  .newsletter-animated-button .circle {
    position: relative;
    z-index: 1;
  }
   
  .newsletter-animated-button .text {
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
   
  .newsletter-animated-button svg {
    position: absolute;
    width: 22px;
    fill: #fff;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
   
  .newsletter-animated-button .arr-1,
  .newsletter-animated-button .arr-2 {
      position: absolute;
      width: 22px;
      fill: #fff;
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .newsletter-animated-button .arr-1 {
      right: 2px;
      transform: translateY(9px); /* Adjust this value as needed */
  }
  
  .newsletter-animated-button .arr-2 {
      left: -25%;
      transform: translateY(9px); /* Adjust this value as needed */
  }
  





  /* .newsletter-animated-button .arr-1 {
    right: 2px;
  }
   
  .newsletter-animated-button .arr-2 {
    left: -25%;
  }
    */
  .newsletter-animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    color: black;
   
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
   
  .newsletter-animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: black;
  }
   
  .newsletter-animated-button:hover .arr-1 {
    right: -25%;
  }
   
  .newsletter-animated-button:hover .arr-2 {
    left: 16px;
  }
   
  .newsletter-animated-button:hover .text {
    transform: translateX(12px);
  }
   
  .newsletter-animated-button:hover svg {
    fill: rgb(255, 246, 246);
    margin-left: 5px !important;
  }
   
  .newsletter-animated-button:active {
    scale: 0.95;
  }
   
  .newsletter-animated-button:hover .circle {
    width: 270px;
    height: 220px;
    opacity: 1;
  }
   
  /* Responsive Styles for Small Screens */
  @media (max-width: 576px) {
    .newsletter_background_img {
      padding: 25px 10px;
    }
   
    .text-center {
      text-align: center;
    }
   
    h2, h5 {
      font-size: 1.5em;
    }
   
    .fs-s {
      font-size: 0.9em;
    }
   
    .fs-xs {
      font-size: 0.8em;
    }
   
    .form-box {
      flex-direction: column;
      align-items: center;
    }
   
    .form-box input,
    .form-box button {
      width: 100%;
      max-width: 300px;
      margin-bottom: 10px;
    }
   
    .flex-container {
      flex-direction: column;
      align-items: center;
    }
   
    .newsletter-divider-line {
      display: none;
    }
   
    .flex-item {
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
    }
   
    .animated-text:hover {
      animation: none;
    }
   
    .newsletter-animated-button {
      padding: 10px;
    }
  }
   