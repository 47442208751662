/* lakshmi css  */
.course-bg-banner {
  background: linear-gradient(to right, #7697b6, #d4ebfa);
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: visible;
}

.padding_css {
  padding: 50px 50px;
}

.best_seller {
  display: flex;
  align-items: center;
  background: rgba(226, 213, 252, .8);
  box-shadow: 0 12px 22px 0 rgba(214, 191, 242, .28);
  padding: 0 20px;
  border: 1px solid #fff;
  border-radius: 500px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -.5px;
  justify-content: center;
  color: var(--color-heading);
  width: max-content;
}

.star_Css {
  margin-top: 6px;
  margin-left: 13px;
}

.rating_css {
  /* background-color: #ffffff21; */
  margin-left: 12px;
  margin-right: 10px;
  padding: 6px;
  margin-top: 10px;
}

.students_css {
  margin-top: 10px;
}


.what_you_learn {
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, .41);
}
.what_you_learn1 {
  border-radius: 6px;
  padding: 15px;
  
}
.topMargin{
  margin-top: -16rem !important;
  background-color: #fff ;
}


.notes-list-style-1 li i {
  padding-right: 12px;
  margin-top: 5px;
  color: #3eb75e;
}

.notes-list-style-1 li {
  display: flex;
  margin: 7px 0;
}

.tick_mark::before {
  content: "\2713";
}

.notes-list-style {
  list-style: none;
  padding-left: 0;
  font-size: var(--erp-fs-18);
  color: var(--erp-paragraph-color);
}

.notes-list-style-1 li {
  margin-bottom: 10px;
}

.notes-show-more-btn {
  margin-top: 20px;
  /* padding: 10px 20px; */
  border-radius: 5px;
  cursor: pointer;
}
.course-bottom{
  margin-bottom: 10%!important;
}
.rating-box {
  border-radius: 5px;
  background: #ff8f3c10;
  text-align: center;
  padding: 10px 10px 22px;
}

.rating-text {
  display: inline-block;
  position: relative;
  display: flex;
  flex: 0 1 !important;
  margin-right: 20px;
}

.review-wrapper .single-progress-bar {
  position: relative;
  top: -14px;
}

.review-wrapper .progress {
  max-width: 70%;
  height: 6px;
  background: #eee;
  border-radius: 0;
  margin-right: 12px;
}

.review-wrapper .progress .progress-bar {
  background: var(--erp-review-color) !important;
  border-radius: 0 !important;
}

.review_border {
  color: var(--erp-review-color);
}

.review-wrapper span.value-text {
  position: absolute;
  bottom: 0px;
  right: 0;
  font-weight: 500;
  font-size: 16px;
}

.name_hover:hover {
  color: var(--erp-teks-blue);
}

.thumb_border {
  height: 50px;
  width: 50px;
  padding: 8px;
  text-align: center;
  border-radius: 50px;
  background: transparent;
  color: var(--erp-paragraph-color);
  margin-right: 10px;
  margin-left: 12px;
  background-color: var(--erp-white-color);
  border: 2px solid var(--erp-light-border);
}

.thumb_border:hover {
  height: 50px;
  width: 50px;
  padding: 8px;
  margin-right: 10px;
  margin-left: 12px;
  text-align: center;
  border-radius: 50px;
  background: transparent;
  color: var(--erp-white-color);
  background-color: var(--erp-teks-blue);
  border: 2px solid var(--erp-white-color);
}

.bg_top_course {
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 100px;

  display: inline-block;
  background: var(--erp-teks-light-orange);
  color: var(--erp-teks-orange);
}




.photo_bg {
  /* background-color: #e9e9e9;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px; */
  height: 100px;
  width: 110px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%, -40%);
}

.trainer_box {
  border-radius: 6px;
  box-shadow: 0px 6px 34px #c7cad6;
  margin-top: 26%;
  /* height: 330px; */
  
}

.trainer-details {
  max-height: 700px; /* Adjust this height as needed */
  overflow-y: auto; /* Adds vertical scrollbar */
}

.trainer-details::-webkit-scrollbar {
  width: 3px; /* Adjust the width of the scrollbar */
  margin-left: 10px;
}

.trainer-details::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounds the edges of the scrollbar handle */
}

.trainer-details::-webkit-scrollbar-track {
  background: lightgrey; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounds the edges of the scrollbar track */
}

/*underline */

.underline {
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.underline:after {
  position: absolute;
  content: '';
  height: 3px;
  bottom: -1px;
  margin: 0 ;
  left: 0px;
  right: 0;
  width: 40%;
  background: var(--erp-teks-blue);
  transition: .5s;
}

/* optional hover classes used with anmiation */
.underline:hover:after {
  transform-origin: right;
  width: 100%;
  background: var(--erp-teks-orange);
}


.notes-show-more-btn {
  margin-top: -10px; /* Adjust as needed */
}
.banner_pragraph_color{
  color: #6b7385 !important;
}
@media only screen and (max-width: 1024px) {
  .review-wrapper .progress {
    max-width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .review-wrapper .single-progress-bar {
    margin-bottom: 15px;
  }

  .rating-text {
    top: 0;
    margin-bottom: 8px;
  }

  .review-wrapper .progress {
    max-width: 83%;
    margin-left: 0;
  }

  .single-progress-bar {
    display: ruby-text !important;
  }
  .card_position {
    position: sticky !important;
    right: 0;
    top: 0;
    background-color: #fff;
}
}

@media(max-width:420px) {
  .star_Css {
    margin-top: 15px;
    margin-left: 0px;
  }
  .card_position {
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    background-color: #fff;
}
}













/* ---------------------------------divyani css */
.course .coursetitle {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  word-break: break-word;
  line-height: 1.19;
  color: #192335;
  position: relative;
  z-index: 2;
  padding-left: 5%
}

.course p {
  color: #6b7385;
  font-size: 14px;
  position: relative;
  z-index: 2;
  padding-left: 6%
}

.main_padding {
  padding: 50px;
}

.course .description {
  font-size: 16px;
  color: #192335;
  position: relative;
  z-index: 2;
  padding-left: 9px;
}

.course_bg {
  /* background-image: url(../images/banner_bg_img-min.png); */
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;

}

.course-card {
  overflow: hidden;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, .41);
  padding: 30px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  height: 100%;
  width: 390px;
  z-index: 3;
  transform: translate3d(0px, 0px, 0px) rotateZ(0deg) scale(1);
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

ul {
  padding-left: 0rem !important;
}

.card_meta li {
  display: inline-block;
  list-style: none;
  margin-top: 10px;
}

.lesson_section li {
  display: inline-block;
  margin-top: 10px;
}

/* ----------------------------- */
.rbt-card {
  overflow: hidden;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, .41);
  /* padding: 30px; */
  border-radius: 6px;
  background: #fff;
  position: relative;
  height: 100%;
}

.course-details-feature .feature-item {
  position: relative;
  z-index: 2;
  padding-left: 3%;
  display: flex;
  align-items: center;

}

.feature-item .badge {
  display: flex;
  border-radius: 30px;
  align-items: center;
  padding: 0.5rem 1rem;
}

.badgebestseller {
  border: 1px solid white;
  border-radius: 20px;
  background-color: #e2d5fccc;
}

.feature-item img {
  margin-right: 5px;
}

.raview-ratingscourse {
  color: var(--erp-review-color);
}

.feature-item.rating a:first-child {
  color: #000;

}

.coursebadge {
  color: #192335;
  text-decoration: none;
}

.author-meta {
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  padding-left: 3%;
}

.author-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.author-info span {
  display: inline-block;
}

.author-info a {
  text-decoration: none;
  color: #192335;
  font-size: 14px;
}

.author-info a:hover {
  color: blue;
}

.liststyle {
  padding-left: 5%;
}

.cardimagecourse {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
}

.card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: auto;
}

/* Custom styles for the course feature box */
.rbt-course-feature-box {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rbt-course-feature-inner {
  position: relative;

}

.section-title {
  margin-bottom: 20px;
}

.rbt-title-style-3 {
  font-size: 1.5rem;
  font-weight: bold;
}


.rbt-show-more-btn:hover {
  color: #0056b3;
}

.section-title {
  font-size: var(--erp-fs-20);
  color: #192335;
}

.short-content {
  font-size: var(--erp-fs-18);
  color: var(--erp-paragraph-color);
}


.shortcontent2 {
  font-size: var(--erp-fs-18);
  color: var(--erp-paragraph-color);
}

/* review desing */
.rbt-review-wrapper {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.course-content .section-title h4 {
  font-size: 24px;
  font-weight: bold;
}

.rating-box {
  text-align: center;
  background-color: #ff8f3c10;
}

.rating-number {
  font-size: 48px;
  font-weight: bold;
  color: #333;
}

.review-wrapper {
  margin-top: 20px;
}

.single-progress-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.rating-text {
  display: flex;
  align-items: center;
  flex: 1;
}

.star-icon {
  color: #ffc107;
  font-size: 16px;
  margin-right: 5px;
}

.empty-star {
  color: #ccc;
}

.progress-container {
  display: flex;
  align-items: center;
  flex: 3;
  margin-left: 15px;

}

.progress {
  width: 100%;
  margin-right: 10px;
  height: 4px;
}


.value-text {
  flex: 0 0 auto;
  font-size: 14px;
  color: #333;
  min-width: 40px;
  text-align: right;
}

.progress-bar {
  background-color: #ff9747;

}

.star-icon {
  color: #ff9747;
}

.sub-title {

  color: #ffc107;
  font-style: 16px;
}


/* featured review */

.has-show-more-inner-content {
  margin-top: 20px;
}

.rbt-featured-review-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rbt-course-review {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.media {
  display: flex;
  align-items: flex-start;
}

.thumbnail {
  flex-shrink: 0;
}

.thumbnail img {

  width: 100px;
  height: 100px;
  object-fit: cover;
}

.media-body {
  flex: 1;
}

.author-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 30px;
  color: #192335;
  font-size: var(--erp-fs-20);
  font-weight: 700;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.featuredrating {
  display: flex;
  gap: 5px;
  margin-left: 30px;
  color: #ffc107;
}

.content {
  margin-top: 10px;
}

.description {
  margin-bottom: 10px;
  margin-left: 30px;
  color: #6b7385;
  font-size: var(--erp-fs-18);
}

.social-icon {
  display: flex;
  gap: 10px;
}

.social-icon li {
  list-style: none;
}

.social-icon a {
  color: #333;
  font-size: 16px;
}

.hover-flip-item-wrapper {
  text-decoration: none;
  color: inherit;
}

.hover-flip-item-wrapper:hover {
  color: #007bff;
}

/* cartcontainer desing from here  */
.section-title .subtitle {
  background-color: rgba(255, 20, 147, 0.1);
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #db7093;
}


.section-title .text-primary {
  color: #007bff;
}

.rbt-card {
  border: none;
  transition: transform 0.3s ease-in-out;
}

.rbt-card:hover {
  transform: translateY(-10px);
}

.rbt-card-img img {
  width: 100%;
  height: auto;
}

.rbtbadge-3 {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
}

.rating i {
  color: #ffc107;
  /* Bootstrap yellow color for stars */
  margin-right: 2px;
}

.rating-count {
  font-size: 14px;
  color: #6c757d;
  /* Bootstrap muted text color */
  margin-left: 5px;
}

.rbt-meta li {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  color: #6c757d;
}

.rbt-avatar img {
  border-radius: 50%;
}

.rbt-price {
  font-size: 18px;
  font-weight: bold;
}

.current-price {
  color: #192335
}

.off-price {
  color: #6b7385;
  text-decoration: line-through;
  margin-left: 10px;
}

.btn-link {
  color: #007bff;
  /* Bootstrap link color */
  font-weight: bold;
  text-decoration: none;
}

.rbt-card-title {
  color: #192335;
  font-size: 22px;
  margin-top: 10px;
  font-weight: 700;
  text-wrap: wrap;
  margin-right: -10px;
}

.rbt-card-text {
  color: #6b7385;
  font-size: 16px;
}

.card-body-course {
  margin-right: 10px;
}

.btn-outline-primary {
  font-size: 0.875rem;
  /* Button text size */
  padding: 0.5rem 1rem;
  /* Button padding */
}

.titletopcourse {
  color: #192335;
  font-size: 30px;
  font-weight: 700;
}



/* 
col-4 design for from section */


.hidden {
  display: none !important;
}

.course-sidebar {
  background: #fff;
  border-radius: .25rem;
  border: 1px solid #ddd;
  z-index: 1000;
}

.gradient-border {
  border: 2px solid;
  border-image: linear-gradient(to right, #9e8ff7, #615ef5);
  border-image-slice: 1;
}

.add-to-cart-button .btn-gradient {
  background-image: linear-gradient(90deg, #2f57efbf, #c586eeab);
  height: 50px;
  width: 150x;
  color: #fff;
}

.buy-now-btn .btn-border {
  border: 2px solid #6b7385;
  color: #192335;

}

.custom-margin-lg {
  margin-top: 10px;
  padding: 0px 35px;
}

.social-icon a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #333;
  border: 1px solid transparent;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.social-icon a:hover {
  color: #fff;
  background-color: #333;
}

.formlistdesign label {
  font-size: 13px;
  color: #212529;
  min-width: '100px'
}

.formlistdesign input {
  font-size: 13px;
  color: #212529;
}

.formlistdesign select {
  font-size: 13px;
  color: #212529;
}

@media (min-width: 992px) {
  .custom-margin-lg {
    margin-top: -500px;
    /* Margin for large screens */
  }
}

@media (max-width: 576px) {
  .course .coursetitle {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.19;
    color: #192335;
    position: relative;
    z-index: 2;

  }

  .course p {
    margin-top: -50px;
  }

  .course .description {
    margin-top: 10px;
    margin-left: 9px;
  }
}


/* relatedcourse desing */
.section-relatedcurse span {
  background-color: #2f57ef21;
  /* Pink opacity background */
  border-radius: 20px;
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #2f57ef;
}

.section-relatedcurse h4 {
  color: #192335;
  font-size: 30px;
  margin-top: 10px;
}

/* flipcartdesing */

.courseflipcardwidth {
  width: 100%;
  max-width: 250px;
  /* Adjust max-width for larger screens */
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  /* Ensure cards stack correctly */
  display: flex;
  flex-direction: column;
  z-index: 1;
  /* Ensure card stays above content */
}

.trainers {
  margin-top: 100px;
  text-align: center;
}

.trainers img {
  margin-top: -80px;
}

.trainers .courseflipcardwidth {
  border-radius: 0;
  box-shadow: 5px 5px 10px #9e8ff7;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  border-radius: 15px;
}

.trainers .courseflipcardwidth:hover {
  background: #9e8ff7;
  color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: 5px 5px 5px #9e9e9e;
}

.course_banner {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11 !important;
}


/* Container for card flipping effect */
.card-wrapper {
  perspective: 1000px;
  /* Perspective for 3D effect */
  position: relative;
  width: 100%;
  /* Make the card responsive */
  margin-bottom: 20px;
  /* Add spacing between cards */
  display: flex;
  justify-content: center;
  /* Center cards horizontally */
}



/* Flip effect on hover */
.card-wrapper:hover .courseflipcardwidth {
  transform: rotateY(180deg);
}

/* Styles for the front and back sides of the card */
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-front {
  background: white;
  z-index: 2;
  /* Ensure the front is above the back when not flipped */
  transform: rotateY(0deg);

}

.card-back {
  background: #2f57ef;
  color: white;
  transform: rotateY(180deg);

}

/* Ensure the content in the card body is centered and responsive */
.card-body {
  padding: 15px;
  text-align: center;
  color: #000;
}

/* Additional responsive adjustments for screens 1024px and below */
@media (max-width: 1024px) {
  .courseflipcardwidth {
    max-width: 90%;
    /* Adjust max-width to fit smaller screens */
    /* max-height: 300px; */

  }

  .card-body img {
    width: 80%;
    /* Adjust image size for smaller screens */
  }

  .card-body h5 {
    font-size: 1rem;
    margin-top: -10px;

  }

  .card-body h6 {
    font-size: 1rem;
    /* Reduce text size */
  }

  .card-body p {
    font-size: 0.875rem;
    /* Reduce text size */
  }

}
.cursor-not-allowed{
  cursor: not-allowed !important;
}
/* 
.banner-courselanding{
  padding-left:70px ;
} */

.courselist-heading{
  padding:10px 35px ;
}
.photo_bg {
  height: 110px; /* Set the desired height for the image */
  width: 120px;  /* Set the desired width for the image */
  position: absolute;
  top: -55px; /* Moves the image up, 50% of its height */
  left: 50%; /* Centers the image horizontally */
  transform: translateX(-50%); /* Corrects the centering offset */
  z-index: 1; /* Ensure the image is above the card */
}
 
.trainer_box {
  position: relative; /* Set position relative so that the image is positioned in relation to this */
  border-radius: 6px;
  box-shadow: 0px 6px 34px #c7cad6;
  padding-top: 60px; /* Creates space for the image */
  margin-top: 65px!important; /* Adjust as needed */
  text-align: center; /* Centers the content inside the card */
  overflow: visible; /* Allows the image to overflow outside the card */
}
 
.photo_bg img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
}

@media(max-width:769px){
  .topMargin{
    margin-top: 0rem !important;
    background-color: #fff ;
  }
}