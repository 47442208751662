.footer-description{
  margin-top: 20px 
}
   
  li {
    text-decoration: none;
    list-style: none;
  }
   
  .button:hover {
    color: #637fea;
  }
  .underline-hover {
    position: relative;
   
    cursor: pointer;
    color: black; /* Default text color */
    text-decoration: none; /* Remove underline from the link */
  }
   
  .underline-hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px; /* Thickness of underline */
    background-color: #637fea; /* Underline color */
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease; /* Smooth transition */
  }
   
  .underline-hover:hover {
    color: #0056b3  !important; /* Text color on hover */
    text-decoration:none;
  }
   
  .underline-hover:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .footer-hr {
    border: 0;
    height: 1px; /* Adjust the height if you want a thicker or thinner line */
    background: rgba(200, 182, 182, 0.9); /* Light color for the line */
    margin: 1em 0; /* Adjust margin as needed */
  }
  .divider-line {
    color: rgba(0, 0, 0, 0.8); /* Light color for the line */
    margin: 0 10px; /* Spacing for the line */
  }
   
   
  /* for button animation */
   
  .animated-button {
    position: relative;
    padding: 10px 28px;
    font-size: 14px;
    font-weight: 600;
    color: black;
    background-color: inherit;
    border: none;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.6s cubic-bezier(0.23, 1, 0.32, 1),
      box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1),
      transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), border-radius 0.6s linear;
  }
   
  .animated-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding: 2px; /* Adjust the thickness of the border */
    background: linear-gradient(90deg, #cfa2e8, #637fea); /* Gradient colors */
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 0; /* Make sure the pseudo-element is behind the button content */
  }
   
  .animated-button .text,
  .animated-button svg,
  .animated-button .circle {
    position: relative;
    z-index: 1; /* Place these elements above the pseudo-element */
  }
   
  .animated-button .text {
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
   
  .animated-button svg {
    position: absolute;
    width: 22px;
    fill: black;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
   
  .animated-button .arr-1 {
    right: 2px;
  }
   
  .animated-button .arr-2 {
    left: -25%;
  }
   
  .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    color: black;
    border-radius: 100px;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
   
  .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    /* color: black; */
  }
   
  .animated-button:hover .arr-1 {
    right: -25%;
  }
   
  .animated-button:hover .arr-2 {
    left: 16px;
  }
   
  .animated-button:hover .text {
    transform: translateX(12px);
  }
   
  .animated-button:hover svg {
    fill: black;
  }
   
  .animated-button:active {
    scale: 0.95;
  }
   
  .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
   