.teacher_main{
    background-color: #f9f9ff;
  }
  .page-list{
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .page-list li{
    display: inline-block;
    font-size: 12px;
   
   
  }
  .error-input {
    border: 1px solid #dc2626 !important;
  }
  .text-danger{
    color: #dc2626 !important;
  }
  .instructor_main{
    background-color: white;
    padding: 50px 220px;
   
  }
  .instructor_header {
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    color: #2f57ef;
    margin-bottom: 15px;
    padding: 10px 20px;
    border-radius: 100px;
   
    display: inline-block;
    background-color: #2f57ef21;
    margin-top: 30px;
  }
  .instructor_sub{
    padding: 0px 80px;
  }
  .custom-border-bottom {
    /* border: none !important;  */
    /* border-bottom: 2px solid #e6e3f1 !important;  */
    /* border-radius: 0 !important;  */
    /* padding-left: 0 !important;  */
    /* padding-right: 0 !important; */
    /* margin-top: 20px; */
    box-shadow: none !important;
  }
  .custom-border-bottom::placeholder {
    font-size: 14px; /* Set placeholder font size to 12px */
    color: #999; /* Optional: Adjust placeholder color */
  }
  .custom-select  {
    font-size: 14px;
    color: #999;
  }
  .custom-select option {
    font-size: 14px;
  }
  .teacher_sliders{
    padding: 50px 220px;
  }

  @media(max-width:768px){
    .justify-text{
      text-align: justify;
    }
  }

  .form-group input::placeholder{
    font-size: 13px !important;
  }
  .trainer-heading{
    font-size: 30px!important;
  }