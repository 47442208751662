.about_main {
    background-image: url(../images/about_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 75vh;
    background-position: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white; /* Adjust the text color as needed */
  }
  
  .about_main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
   
  }
  
  .about_header {
 
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    color: #e99678;
    margin-bottom: 15px;
    padding: 10px 20px;
    border-radius: 100px;
    text-transform: uppercase;
    display: inline-block;
    background-color: #2f57ef21;
    margin-top: 30px;
  }
/* 
  .about_button1 {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;

    border-radius: 5px;
   
    text-decoration: none;
    transition: background-color 0.3s ease;
  } */
  .about_content {
    
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  /* .about_button1 {
    background-size: 300% 100%;
    background-image: linear-gradient(to right, #2f57ef, #b966e7, #b966e7, #2f57ef);
    height: 60px;
    width: 180px;
    margin-top: 20px;
    line-height: 60px;
    color: white;
    font-size: 16px;
    letter-spacing: .5px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all .4s ease-in-out;
    border-radius: 6px;
    border: 0;
    outline: none;
    text-decoration: none;
   
  } */

  .about_card_section {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    padding: 20px;
    max-width: 1200px;
    flex-wrap: wrap; /* Ensure that the cards wrap when there's not enough space */
  }
  
  .about_card {
    /* background-color: #fff; */
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    object-fit: contain;
    /* padding: 20px; */
    width: 90%;
    height: 50%;
    /* border-radius:10px; */
    margin-bottom: 20px; /* Add margin between rows on smaller screens */
  }
  
  .about_card_name {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .about_card h6 {
    margin-top: 10px;
    font-size: 12px;
 color: #555;
  }
  .teacher_description{
    padding: 0 29%;
    letter-spacing: -.5px;
    font-size: 12px;
    line-height: 1.45;
  }
  
  /* Media Queries */
@media (max-width: 1024px) {
    .about_card {
      width: 45%; /* Two cards per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .about_card {
      width: 100%; /* Single card per row on small screens */
    }
    
    .about_button1 {
   
        height: 50px;
        width: 140px;
        margin-top: 30px;
        line-height: 60px;
      
        font-size: 12px;
       
       
      }
  }
  
 
