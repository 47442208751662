.login_bg_image {
    background-image: url(/src/assets/images/LoginBg.png);
    background-position: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
}
.cursor-not-allowed{
    cursor: not-allowed !important;
}