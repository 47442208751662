
.banner_bgs {
  background: linear-gradient(to right, #2a619d, #eb6329);
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;

}
.underline-hover {
  position: relative;
  cursor: pointer;
  color: black; /* Default text color */
  text-decoration: none; /* Remove underline from the link */
}
.banners .title {
    font-size: 38px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px;
    word-break: break-word;
    line-height: 1.19;
}
.main_paddings{
    padding:20px;
}
.banners .description {
    color: black;
    font-size: 18px;
    margin-bottom: 40px;
    font-weight: 400;
    padding-right: 24px;
}



.banners-card {
    overflow: hidden;
    box-shadow: 0px 6px 34px rgba(215,216,222,.41);
    padding: 30px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    height: 100%;
    width: 390px;
    z-index: 3;
    transform: translate3d(0px, 0px, 0px) rotateZ(0deg) scale(1);
}

 

.rbt-badge-2 {
    display: flex;
    align-items: center;
    background: rgba(226, 213, 252, .8);
    box-shadow: 0 12px 22px 0 rgba(214, 191, 242, .28);
    padding: 0 20px;
    border: 1px solid #fff;
    border-radius: 500px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.5px;
    justify-content: center;
    color: var(--color-heading);
    width: max-content;
}

  .form-des{
    background-color: transparent;
    border:1px solid white;
    border-radius: 10px;
  }
  .nav-heads {
    display: flex;
    justify-content: left;
    padding-left: 0;
  }
 
  .courses-btn{
    border-radius: 500px;
    border:1px solid #6B7385;
    background-color: white;
    color: #6b7385;
    padding: 0 20px;
  }
  .courses-btn1{
    border-radius: 500px;
    border:1px solid #6B7385;
    background-color: white;
    color: #6b7385;
    padding: 0 20px;
  }
  .rbt-cards {
    overflow: hidden;
    box-shadow: 0px 6px 34px rgba(217, 215, 215, 0.41);
    /* padding: 15px; */
    /* border-radius: 6px; */
    background: #fff;
    position: relative;
    height: 100%;
  }
  .rbt-hover:hover {
    transform: scale(1.02);
    transition: 256ms ease-in-out;
  }
  .search-inp{
    border: 1px solid grey;
    border-radius: 10px!important;
    padding: 5px 30px;
    background-color: transparent;
    /* width: 100%; */
  }
  .filter-btn{
    border: 2px solid white;
    border-radius: 100px;
    padding: 0 24px;
   
    background-color: white;
    color: black;
  }
  .search-inp::placeholder {
    color: gray;
  }
  .filter-icon{
    
   color: #000; 
  }
  .search-inp:focus {
   
    outline: none!important; /* Remove the default focus outline */
  }
  .home-course{
    color: #6b7385;
    font-size: 14px;
  }
  .home-p{
    font-size: 14px;
  }
  a{
    text-decoration: none !important;
  }
  .courselist-img{
    width: 524px!important;
    height: 200px!important;
    border-radius: 0px !important;
  }
  .view-span {
    position: relative;
  }
  .under-line1::before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #2a619d;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .6s;
  }
   .view-span:hover .under-line1::before {
    transform-origin: left;
    transform: scaleX(1);
  }
  .course-categories{
    height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media (max-width: 510px) {
    .search-inp{
      border: 1px solid grey;
      border-radius: 10px!important;
      padding: 5px 30px;
      background-color: transparent;
      width: 300px;
      /* width: 100%; */
    }
  }
  @media(max-width:1000px){
    .course-categories{
      font-size: 14px!important;
    }
  }
  @media(max-width:867px){
    .course-categories{
      font-size: 12px!important;
    }
  }