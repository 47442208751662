@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  scroll-behavior: smooth;
}
:root,
[data-erp-theme="light"] {
  /* font-size */
  --erp-fs-xxs: 11px;
  --erp-fs-xs: 12px;
  --erp-fs-s: 0.8125rem;
  --erp-fs-m: 0.9375rem;
  --erp-fs-lg: 1.25rem;
  --erp-fs-7: 0.775rem;
  --erp-fs-13: 0.8125rem;
  --erp-fs-14: 14px;
  --erp-fs-16: 1rem;
  --erp-fs-17: 17px;
  --erp-fs-18: 18px;
  --erp-fs-20: 20px;
  --erp-fs-22: 22px;
  --erp-fs-24: 24px;
  --erp-fs-26: 26px;
  --erp-fs-30: 30px;
  --erp-fs-44: 44px;
  --erp-fs-50: 50px;

  /* font-weight */
  --erp-fw-100: 100;
  --erp-fw-300: 300;
  --erp-fw-400: 400;
  --erp-fw-500: 500;
  --erp-fw-600: 600;
  --erp-fw-700: 700;

  /* line-height */
  --erp-lh-100: 1.2;
  --erp-lh-200: 1.5;
  --erp-lh-300: 1.6;
  --erp-lh-400: 1.8;
  --erp-lh-500: 2;
  --erp-lh-15: 15;
  --erp-lh-25: 25px;

  /* colors */
  --erp-paragraph-color: #6b7385;
  --erp-review-color: #ff9747;
  --erp-star-icon: #ffe600;
  --erp-light-gray: #c4c7ce;
  --erp-color-contact-btn: #b966e7;
  --erp-bg-contact-btn: #b966e721;
  --erp-bg-contact-btn1: #9bc3ffa9;
  --erp-bg-contact-gradient: linear-gradient(180deg, #eedefd, #fff);
  --erp-bg-contact-gradient-btn: linear-gradient(90deg, #2f57ef, #b966e7);
  /* --erp-bg-teks-gradient-btn: linear-gradient(to right,#0085CA, #FD8112); */
  --erp-bg-teks-gradient-btn: #2a619d;
  --erp-footer-text-color: #6a7284;
  --erp-white-color: #fff;
  --erp-teks-blue: #2a619d;
  --erp-teks-orange: #eb6329;
  --erp-light-border: #e6e3f1;
  --erp-teks-light-orange: #f7bea5;
}

.font-size-xxs {
  font-size: var(--erp-fs-xxs) !important;
}

.fs-xs {
  font-size: var(--erp-fs-xs);
}

.fs-s {
  font-size: var(--erp-fs-s) !important;
}

.fs-m {
  font-size: var(--erp-fs-m) !important;
}

.fs-lg {
  font-size: var(--erp-fs-l);
}

.fs-7 {
  font-size: var(--erp-fs-7);
}

.fs-13 {
  font-size: var(--erp-fs-13);
}

.fs-14 {
  font-size: var(--erp-fs-14) !important;
}

.fs-16 {
  font-size: var(--erp-fs-16) !important;
}

.fs-17 {
  font-size: var(--erp-fs-17) !important;
}

.fs-18 {
  font-size: var(--erp-fs-18) !important;
}

.fs-20 {
  font-size: var(--erp-fs-20);
}

.fs-22 {
  font-size: var(--erp-fs-22);
}

.fs-24 {
  font-size: var(--erp-fs-24);
}
.fs-26 {
  font-size: var(--erp-fs-26);
}
.fs-30 {
  font-size: var(--erp-fs-30);
}
.fs-44 {
  font-size: var(--erp-fs-44) !important;
}
.fs-50 {
  font-size: var(--erp-fs-50);
}

/* font-weight */
.fw-100 {
  font-weight: var(--erp-fw-100);
}

.fw-300 {
  font-weight: var(--erp-fw-300);
}

.fw-400 {
  font-weight: var(--erp-fw-400);
}

.fw-500 {
  font-weight: var(--erp-fw-500);
}

.fw-600 {
  font-weight: var(--erp-fw-600);
}

.fw-700 {
  font-weight: var(--erp-fw-700);
}

/* line height */
.lh-100 {
  line-height: var(--erp-lh-100);
}

.lh-200 {
  line-height: var(--erp-lh-200);
}

.lh-300 {
  line-height: var(--erp-lh-300);
}

.lh-400 {
  line-height: var(--erp-lh-400);
}

.lh-500 {
  line-height: var(--erp-lh-500);
}
.lh-25 {
  line-height: var(--erp-lh-25);
}

.lh-15 {
  line-height: 15px;
}
/* ----colors ---- */
.banner_pragraph_color {
  color: var(--erp-paragraph-color);
}
.review_color {
  color: var(--erp-review-color);
}
.star-icons {
  color: var(--erp-star-icon) !important;
}
.light_gray_color {
  color: var(--erp-light-gray);
}
.bg_contact_btn {
  background: var(--erp-bg-contact-btn);
}
.bg_contact_btn1 {
  background: var(--erp-bg-contact-btn1);
}
.bg_contact_gradient {
  background: linear-gradient(to right, #7697b6, #d4ebfa);
}
.bg_contact_gradient-btn {
  background: var(--erp-bg-contact-gradient-btn);
}
.bg-teks-gradient-btn {
  background: var(--erp-bg-teks-gradient-btn) !important;
  border: none !important;
  outline: none !important;
  border-radius: 0.5rem !important;
  padding: 0 15px;
  color: #fff;
}
.bg-teks-gradient-btn:hover {
  color: #fff !important;
}
.contact_btn_color {
  color: var(--erp-color-contact-btn);
}
.footer-text-color {
  color: var(--erp-footer-text-color);
}
.white_color {
  color: var(--erp-white-color);
}
.teks_orange {
  color: var(--erp-teks-orange);
}
.teks_blue {
  color: var(--erp-teks-blue);
}
.teks_bg_blue {
  background-color: var(--erp-teks-blue) !important;
}
.wid-3r {
  width: 8rem !important;
}

.bg_orange {
  background-color: var(--erp-teks-orange) !important;
}
.select_is_invalid {
  border: 1px solid #dc3545 !important; /* Red border color */
  border-radius: 0.25rem; /* Rounded border */
}

/* --------- */
.mb--4 {
  margin-bottom: 4%;
}

.mx-5r {
  margin: 0 5rem;
}
.height5 {
  height: 80vh;
}

.modal-footer {
  border-top: 1px solid #fff !important;
}
.cursor_pointer {
  cursor: pointer;
}

.video-size {
  height: 800px !important;
}
.slot-font {
  font-size: 14px !important;
  background: var(--erp-bg-teks-gradient-btn) !important;
  color: white;
  font-weight: 600;
  padding: 7px;
  border: none;
  border-bottom-left-radius: 6px;
  border-top: 1px solid rgb(196, 191, 191);
  width: 197px;
}
.slot-font1 {
  font-size: 14px !important;
  background: white !important;
  color: black;
  font-weight: 600;
  transition: background-color 0.3s ease;
  padding: 7px;
  border: none;
  border-top: 1px solid rgb(196, 191, 191);
  width: 197px;
}
.slot-font1:hover {
  background-color: #eb6329 !important;
  color: white;
}
table td {
  border-top: none !important;
}
.title {
  font-size: 30px !important;
}
.badge2 {
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
  width: max-content;
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 100px;
  display: inline-block;
  background-color: var(--erp-teks-orange);
  margin-top: 30px;
}
@media (max-width: 820px) {
  .height5 {
    height: 30vh;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 24px !important;
  }
}
@media (max-width: 375px) {
  .title {
    font-size: 18px !important;
  }
}

/*btn*/
.rbt-btn {
  padding: 0 26px;
  background: var(--erp-bg-teks-gradient-btn) !important;
  height: 60px;
  line-height: 60px;
  color: var(--erp-white-color);
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  /* z-index: 1; */
  transition: all 0.4s ease-in-out;
  border-radius: 10px !important;
  border: 0;
  outline: none;
  text-decoration: none;
}
.rbt-btn.btn-sm {
  padding: 0 22px;
  font-size: var(--font-size-b3);
  height: 45px;
  line-height: 43px;
}
.rbt-btn:hover {
  color: var(--erp-white-color) !important;
}

.bg-apps {
  color: var(--erp-teks-blue);
}
.modal-backdrop.show {
  opacity: 0; /* If needed to reset opacity */
}

.rbt-btn-org {
  background: var(--erp-teks-orange) !important;
  height: 60px;
  line-height: 60px;
  color: var(--erp-white-color);
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  /* z-index: 1; */
  transition: all 0.4s ease-in-out;
  border-radius: 10px !important;
  border: 0;
  outline: none;
  text-decoration: none;
}
.rbt-btn-org.btn-sm {
  padding: 0 22px;
  font-size: var(--font-size-b3);
  height: 45px;
  line-height: 43px;
}
/* Add to your CSS file */
/* Add this CSS to your stylesheet */

.trainer-scroll-container {
  height: 170px !important;
  overflow-y: auto; /* Disable vertical scrolling */
}

.trainer-scroll-container::-webkit-scrollbar {
  width: 3px; /* Set scrollbar width to 5px */
  margin-left: 10px !important;
}

.trainer-scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.trainer-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.trainer-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.trainer-scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.tech-card {
  /* border-radius: 8px; */
  /* margin-top: 10px; */
  /* width: 300px; */
  height: 300px;
  box-shadow: 0px 10px 12px rgba(18, 2, 2, 0.1);
  max-width: 300px; /* Adjust the max width as needed */
  flex: 1 1 300px; /* Allow the cards to grow and shrink */
  margin: 10px;
}

.techcard-image {
  width: 100%;
  height: 70%;
  object-fit: contain;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.techcard-title {
  font-size: 1.3em;
  margin: 12px 0;
  padding: 0px 20px;
  text-wrap: wrap;
}

.techcard-content {
  font-size: 0.8em;

  /* border-top: 1px solid rgb(179, 177, 177); */
  padding: 0px 20px;
  color: #555;
  /* display: flex;
  align-items: end; */
}
a:hover {
  text-decoration: none !important;
}
.trainer-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.reviewSliderContentBlock {
  background: #fff;
  box-shadow: 25px 25px 55px rgba(0, 0, 0, 0.08);
  border-radius: 35px;
  padding: 20px;
  width: 95%;

  height: 300px;
  border: 1px solid #e4e0e0;
}
.reviewSliderContentBlock .popularHead .popular-courses-image img {
  width: 90px;
  max-width: 100%;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
}
.reviewSliderContentBlock .popularHead .popular-courses-content {
  font-weight: 800;
  line-height: 33px;
  color: #1f1f1f;
  font-size: 18px;
}
.reviewSliderContentBlock .popularHead {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 23px;
}
.reviewSliderContentBlock .popularHead .popular-courses-image {
  width: auto;
  height: auto;
  box-shadow: none;
  background: 0 0;
  line-height: normal;
  margin-right: 20px;
}

.price-background {
  background-color: #eb6329;

  padding: 10px 40px;

  line-height: 35px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-size: 12px;
}

.table th {
  max-width: 100px !important;
  background-color: #d9d9df !important;
  z-index: auto;
  padding: 0.4rem !important;
}
.table td {
  padding: 0.4rem !important;
}
.actualAmount {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px !important;

  color: #767676;
  text-decoration: line-through;
}
.freecourse {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  letter-spacing: 0.04em;
  color: #767676;
  text-decoration: none !important;
}
.expert-img {
  width: 100%;
  height: 400px !important;
  object-fit: cover;
}
.card-texting {
  text-align: justify;
  /* text-justify: inter-word; */
  word-spacing: -5px !important;
}
/* Vineeth CSS */
.hide {
  display: none;
}
.show {
  display: block;
}
.teks_bg_blue {
  background: var(--erp-bg-teks-gradient-btn);
  color: var(--erp-white-color);
}
.teks_bg_orange {
  color: var(--erp-white-color);
  background: var(--erp-teks-orange);
}
.responsive-w-25 {
  width: 100% !important;
}

@media (max-width: 992px) {
  .responsive-w-25 {
    width: 25% !important;
  }
  /* used in Course landing page for heading and search for responsive */
  .flex_md_column {
    flex-direction: column !important;
    justify-content: start !important;
  }
  /* used in Course landing page for search responsive in break points if not used getting scrollor at bottom */
  .w_100 {
    width: 50% !important;
  }
}
@media (max-width: 510px) {
  .category-heading {
    font-size: 13px !important;
  }
  /* used in Course landing page for search responsive in break points if not used getting scrollor at bottom */
  .w_100 {
    width: 100% !important;
  }
  /* used in Course landing page for heading and search for responsive */
  .flex_md_column {
    flex-direction: column !important;
    justify-content: start !important;
    padding:0 10px 0 10px !important;
  }
  .search-list{
   padding: 10px;
   margin: 1px!important;
  }
}

/* Vineeth CSS */
