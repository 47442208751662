        /* Basic styling for the page */
        .app {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            /* // height: 100vh; */
            margin: 10px 0;
            /* // background-color: #f5f5f5; */
            text-align: center;
          }
  
          .popup {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index:1050;
            padding: 20px 0px;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-y: scroll;
            top: 0px;
            margin-top: 0px;
            padding-top: 20px;
            padding-bottom: 20px;
  }
  
          .popup-content {
            position:absolute !important;
            top:10%;
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          }
  