/* .active {
    background: #2a619d !important;
    color: #fff !important;
  }
  */
   /* .bg-white{
    background:  linear-gradient(
        to right,#0087ca7c,
        #fd80129a
     ) ;
     color: rgb(0, 0, 0);
  }  */

.course-btn{
    background: #fff;
    height: 50px;
    padding: 4px 4px 4px 8px !important;
    margin-bottom: 1rem;
    width: 100%; 
}
  .more-course span {
    height: 50px !important;
    width: 50px !important;
    background-color: rgb(255, 24, 93);
    border-radius: 50%;
  }

  .round{
    border-radius: 100% !important;
  }
  
  .more-course span img{
    /* display: none; */
    height: 40px;
    width: 40px;
  }
  .course-sides {
    max-height: 800px; /* Adjust the height as needed */
    overflow-y: auto!important; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
  }
  
  /* WebKit Browsers (Chrome, Safari) */
  .course-sides::-webkit-scrollbar {
    width: 6px; 
    /* Set scrollbar width */
  }
  
  .course-sides::-webkit-scrollbar-track {
    background: transparent; /* Hide the track */
  }
  
  .course-sides::-webkit-scrollbar-thumb {
    background: transparent; /* Scrollbar thumb color */
    border-radius: 3px; /* Optional: for rounded corners */
  }
  
  .course-sides::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
  }
  
  /* Hide scroll arrows in WebKit browsers */
  .course-sides::-webkit-scrollbar-button {
    display: none; /* Hide arrows */
  }
  
  
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #5165a5!important;
}

  @media(max-width:700px){
    .span-container-span{
        display: none !important;
        height: 40px;
        width: 40px;
      }
    .span-container img{
        width: 100% !important;
        height: 100% !important;
      }
      .course-btn{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
      }
      .course-side h5{
        display: none;
      }
      .course-side button{
        width: 80px !important;
      }
  }
  .view-span {
    position: relative;
  }
  .under-line1::before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #2a619d;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .6s;
  }
   .view-span:hover .under-line1::before {
    transform-origin: left;
    transform: scaleX(1);
  }